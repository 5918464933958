<template>
  <div id="accountDetail">
    <h4>{{clientName}} 
        <span v-if="isMember" style="color:#fff;background-color:#67C23A;padding:5px;font-size:12px;font-weight:normal;border-radius:5px;">Member</span>
        <el-link v-else style="line-height:21.5px;font-size:12px;" type="danger" :underline="false" @click="dialogVisible = true"> Join Member </el-link>
        <el-button type="text" size="mini" style="margin-left:12px;" @click="passwordDialogVivisble = true">Change Password</el-button>
    </h4>
    <h5>{{clientPoints}} Points  <el-link style="font-size:13.28px;text-decoration:underline;" href="/point">view history</el-link></h5>
    <h5>{{clientCardNo}}</h5>
    <h5>+{{clientMobile}}</h5>
    <h5>{{clientEmail}}</h5>
    <h5>{{clientCompany}}</h5>
    <h5>{{clientOffice}}</h5>
    <el-dialog title="Join Memeber" :visible.sync="dialogVisible" :width="dialogWidth">
      <span style="margin:0px 0px 10px 0px;display:block;text-align:left;">Input invite code to activate memebership</span>
      <span><el-input v-model="inviteCode" placeholder="invite code"></el-input></span>
      <span slot="footer" style="text-align:right;">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="joinMemberClick">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Change Password" :visible.sync="passwordDialogVivisble" :width="dialogWidth">
      <span style="margin:0px 0px 10px 0px;display:block;text-align:left;">Input Old Password</span>
      <span><el-input v-model="oldPassword" placeholder="password" type="password" show-password></el-input></span>
      <span style="margin:20px 0px 10px 0px;display:block;text-align:left;">Input New Password</span>
      <span><el-input v-model="newPassword" placeholder="password" type="password" show-password></el-input></span>
      <span style="margin:20px 0px 10px 0px;display:block;text-align:left;">Repeat New Password</span>
      <span><el-input v-model="repeatPassword" placeholder="password" type="password" show-password></el-input></span>
      <span slot="footer" style="text-align:right;">
          <el-button @click="passwordDialogVivisble = false">取 消</el-button>
          <el-button type="primary" @click="passwordChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import userApi from '../../api/user'

export default {
    name: 'accountDetail',
     data(){
        return {
            dialogVisible: false,
            passwordDialogVivisble: false,
            inviteCode: '',
            oldPassword: '',
            newPassword: '',
            repeatPassword: ''
        }
    },
    props: ['view'],
    computed: {
        ...mapState('client', ['client', 'token']),
        ...mapGetters('client', ['isMember']),
        dialogWidth(){
            if(this.view && this.view === 'mobile') {
                return "80%"
            }
            return "30%"
        },
        clientName(){
            if(this.client === null) return ''
            return this.client.name
        },
        clientPoints(){
            if(this.client === null) return 0
            return this.client.points
        },
        clientCardNo(){
            if(this.client === null || this.client.card_number.length === 0) return 'no membership card'
            return 'No.' + this.client.card_number
        },
        clientMobile(){
            if(this.client === null) return ''
            return this.client.contact_no
        },
        clientEmail(){
            if(this.client === null || this.client.email.length === 0) return 'no email'
            return this.client.email
        },
        clientCompany(){
            if(this.client === null) return ''
            return this.client.company_name
        },
        clientOffice(){
            if(this.client === null) return ''
            return this.client.location.name
        }
    },
    mounted(){
        this.remoteRefreshClient()
    },
    methods: {
        ...mapActions('client',[
            'refreshClient'
        ]),
        remoteRefreshClient(){
            userApi.getClientById(this.token).then(data=>{
                this.refreshClient({
                    client: data
                })
            })
        },
        joinMemberClick(){
            this.dialogVisible = false
            if(this.inviteCode.length === 0) return
            userApi.joinMember(this.inviteCode, this.token).then(data=>{
                this.refreshClient({
                    client:data
                })
            }).catch(err=>{
                if(err.message == 'USED_CODE') {
                    this.$message.error('Invite Code Has Been Used')
                } else {
                    this.$message.error('Invite Code Error')
                }
          })
        },
        passwordChange() {
            if (this.oldPassword === 0 || this.newPassword.length === 0 || this.repeatPassword.length === 0){
                return
            }
            if (this.newPassword !== this.repeatPassword) {
                 this.$message.error('new password and repeat not match')
                 return
            }
            if (this.newPassword.length < 6) {
                this.$message({
                    message: 'password must be more than 6 characters',
                    type: 'error'
                })
                return
            }
            let sha256 = require('js-sha256')
            var data = {
                old_password: sha256(this.oldPassword),
                new_password: sha256(this.newPassword)
            }
            userApi.changePassword(data, this.token).then(resp=>{
                if(resp.status === 200 && resp.data.code === 200) {
                    this.$message({
                        message: 'change password success!',
                        type: 'success'
                    })
                    this.passwordDialogVivisble = false
                    this.$emit('autoLogout')
                } else {
                    this.$message({
                        message: 'old password is not correct',
                        type: 'error'
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>