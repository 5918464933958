<template>
  <div></div>
</template>

<script>
import userApi from '../../api/user'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'myAccount',
    data(){
        return {
            paid: 0,
            delivered: 0,
            commented: 0
        }
    },
    computed: {
        ...mapState('client', ['token'])
    },
    mounted(){
        this.getClientOrderStat()
    },
    methods: {
        ...mapActions('client',[
            'logout',
        ]),
        ...mapActions('cart', [
            'clearGoods'
        ]),
        ...mapActions('order', [
            'clearOrders'
        ]),
        logoutClick(){
            this.clearGoods()
            this.clearOrders()
            this.logout()
            window.location.href = '/'
        },
        getClientOrderStat(){
            userApi.getClientOrderState(this.token).then(resp=>{
                this.paid = resp.paid
                this.delivered = resp.delivered
                this.commented = resp.commented
            })
        }
    }
}
</script>

<style>

</style>